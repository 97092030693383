.discover-container 
{
    display: flex;
    align-items: center;
    justify-content: center;
    
    position: relative;
    
    
    z-index: 3;
    padding: 0 1vw;
    margin: 1vw auto 0 ;
    
    width: 75%;
    height: 13vw;
    border-radius: 2.3vw;

    background-color: #018EBB;
    .big 
    {
        flex: 2;
    }
    .small 
    {
        flex: 1;
    }
    .btn 
    {
        position: relative;

        margin: 0.65vw;
        
        height: 75%;
        width: auto;
        border-radius: 2.21vw;
        
        line-height: 1.17vw;
        font-size: 1.3vw;
        background-color: #FAF9F2;
        transition: transform 0.3s ease-in-out;
        .content
        {
            margin:2.27vw;
            line-height: 1.95vw;
            span 
            {
                border-bottom: 0.25vw solid #018EBB;
                
                font-size: 1.56vw;
                font-weight: 600;
                color: #000018;
            }
        }
        .arrow-effect 
        {
            position: absolute;
            right: 1.3vw;
            bottom: 1.3vw;

            height: 2.8vw;
            width:2.8vw;
            border-radius: 100%;
            
            background-color: #000018;
            transition: all 0.3s ease-in-out;
        }
    }
}