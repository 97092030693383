.header 
{
    margin-left: 10.5vw;
    .presentation 
    {
        position: relative;

        padding: 2.27vw;
        margin-top: 4.22vw;
        
        max-width: 48%;
        border-radius: 2.35vw;
        box-shadow: -0.13vw 0.26vw 0.5vw rgba(0, 0, 24, 0.15);
        
        font-size: 1.3vw;
        text-align: justify;
        line-height: 2vw;
        background-color: #FAF9F2;
    }
}
.agence-illustration 
{
    position: absolute;
    top: 15vw;
    right:5vw;
    
    z-index: 1;

    height:40vw;
}

.team 
{
    
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    position: relative;

    margin:12vw 10.5vw 0 auto ;
    padding: 3.57vw 5.2vw;

    max-width: 42.6vw;
    border-radius: 2.34vw;
    background-color: #E9E8E3;
    .title 
    {
        margin-top: 0;

        font-size: 2.6vw;
        text-align: right;
    }
    .team-content
    {
        margin: 2.6vw 3.25vw 0 0;

        text-align: justify;
        font-size: 1.3vw;
        line-height: 2vw;
        .blue 
        {
            font-weight: 500;
        }
    }
    .blue-grid 
    {
        position: absolute;
        left: -3vw;
        top: 1.7vw;

        z-index: -10;
        transform: rotate(90deg) translateY(48%);
    }
}
.why 
{
    margin: 0 4vw 10vw 4vw;
    padding: 2.8vw 0 0.1vw 0;
    
    width: 92vw;
    height: fit-content;
    border-radius:4.55vw;
    
    background-color: #E9E8E3;
    .title
    {
        margin: 0 11vw;
        font-size: 2.6vw;
    }
    .why-content
    {
        .reveal 
        {
            display: inline;
            width: fit-content;
        }

        display: flex;
        text-align: justify;
        
        margin: 3vw 2.5vw;
        
        font-size: 1.3vw;
        line-height: 2.1vw;
        strong
        {
            font-weight: 600;
            color: #000018;
        }
        .left
        {
            margin-left:5vw;
            max-width:73%;
        }
        .right
        {
            margin-left: 2vw;
            ul 
            {
                margin: 0vw 3vw;
                li 
                {
                    margin-top:2vw ;
                    
                    max-width: 80%;
                    
                    list-style-type: disc;
                    color: #018EBB;
                    span 
                    {
                        color: #000018;
                    }
                }
            }
        }
    }
}