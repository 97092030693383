.mobile-nav
{
  display: flex;
  align-items: center;
  justify-content: space-between;
  
  z-index: 2;

  margin: 6vw auto;
  
  width: 90vw;
  height: 15vw;
  border-radius: 10vw;
  box-shadow: 0 10px 20px rgba(0, 0, 24, 0.07);
  .logo 
  {
    height: 11.5vw;
    margin-left: 4.5vw;
  }
  .menu
  {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
    
    position: absolute;
    right: 9.5vw;
    
    z-index: 1;
    
    width: 8vw;
    height: 7vw;
    .line 
    {
      display: block;
      
      height: 1vw;
      border-radius: 10vw !important;
      background: #000018;
    }
    .line1 
    {
      width: 80%;
      
      transform-origin: 0% 0%;
      transition: all 0.4s ease-in-out;
    }
    .line2 
    {
      width: 60%;
      transition: all 0.2s ease-in-out;
    }
    .line3 
    {
      width: 100%;
      transition: all 0.4s ease-in-out;
      transform-origin: 0% 100%;
    }
  }
  .cross 
  {
    position: absolute;
    z-index: 1000;
    .line 
    {
      height: 1.3vw;    
    }
    .line1 
    {
      width: 8vw;
      transform: rotate(45deg);
      background: #faf9f3;
    }
    .line2
    {
      transform: scaleY(0);
      background: #faf9f3;
    } 
    .line3 
    {
      width: 8vw;
      transform: rotate(-45deg);
      background: #faf9f3;
    }
  }
  
  .nav-list 
  {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
  
    position: absolute;
    left: 0;
    right: 0;
    top: 0;

    z-index: 1;
    
    padding-bottom: 20vw;
  
    min-height: 125vw;
    height: fit-content;
    border-radius: 0 0 12vw 12vw;
    background-color: #000018;
  
    transition: all 0.5s ease-in-out;
    .nav-link, a
    {
      cursor: pointer;
      margin-top: 14vw;

      font-size: 7vw;
      color: #faf9f3;
    }
    .btn 
    {
      display: flex ;
      align-items: center;
      
      padding: 1.5vw 0 1.5vw 5vw;
      
      border-radius:15vw;
      font-weight:500;
      font-size: 7vw;
      color: #000018;
      background-color: white;
      .arrow-effect 
      {
        display:inline-block;
        margin: 0 1.25vw 0 3vw;
        
        height:9vw;
        width: 9vw;
        
        background-color: #000018;
        img 
        {
          height: 4vw;
        }
      }  
    }
    .declencheur
    {
      display: flex;
      flex-direction: column;
      width: 100%;
      .arrow 
      {
        display: inline-block;
        vertical-align: center;
        
        margin-left: 1vw;
        
        height: 5vw;
        transition: all 0.4s ease;
      }
      .show 
      {
        right: 0 !important;
      }
      .solutions
      {
        
        align-items : center;
        flex-direction: column;
        
        position: absolute;
        right: -100%;
        top: 0;

        z-index: 10;
        
        padding-top: 22vw;
        overflow: visible scroll; // (x, y) 
        
        width: 100%;
        height: 118vw;
        background-color: #000018;
        border-radius: 0 0 12vw 12vw;

        transition:  all ease-in 0.3s;
        .solutionTitle
        {
          position: absolute;
          top: 7%;
          left: 25%;
        }
        .retour 
        {
          position: absolute;
          top: 7%;
          left: 9vw;

          height: 6vw;
          transform: rotate(180deg);
        }
          .solution-element
          {
            display: block;
            
            margin-left: auto;
            margin-right: auto;
            
            width: 90%;
            height: 24vw;

            border-bottom: 1px solid rgba(1,142,187, 0.7);
            &:last-of-type
            {
              border-bottom: none;
            }
            h1
            {
              font-size: 5vw;
              font-weight: 450;
            }
            p 
            {
              margin-top: 3vw;
              
              line-height: 4vw;
              font-size: 3.3vw;
              font-weight: 300;
              color: #faf9f2;
            }
          }
        }
    }
  }
  .hide 
  {
    transform: translateY(-110%);
  }
}