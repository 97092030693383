@import '../../../App.scss';



@mixin effet {
    content: "";
    position: absolute;
    bottom: -0.52vw;
    left: 0;

    width: 100%;
    height: 0.11vw;
    border-radius: 0.65vw;
    background-color: #018EBB;
    
    transform-origin: bottom right;
    transform: scaleX(0);
    transition: transform 0.2s ease-out;
    
}
@mixin effetHover {
    transform-origin: bottom left;
    transform: scaleX(1);
}

.scroll 
{
    top: 1.3vw !important;
    
    padding-right: 0.8vw !important;

    width: 90% !important;
    box-shadow: 0 0.13vw 1vw rgba(0, 0, 24, 0.07);
    .sub-nav
    {
        transform: translateX(-50.5%);
    }
}


.navbar 
{
    display: flex;
    justify-content: space-between;
    align-items: center;
    
    position: fixed;
    left: 50%;
    top: 2vw;

    z-index: 1000;
    padding: 0 1.6vw;

    width: calc(90% - 2.4vw);
    height: 3.9vw; 
    border-radius: 6.5vw;
    background-color: #FAF9F2;

    transform: translateX(-50%);
    transition: all 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    .logo-container 
    {
        display: flex;
        align-items: center;
        height: 100%;
        .logo 
        {
            position: relative;
            top: -0.065vw;
            height: 66.66%;
        }
    }
    .nav-link-list 
    {
        display: flex;
        justify-content: center;
        align-items: center;
        .nav-link 
        {
            padding: 0.65vw 1.3vw;
            font-size: 1.04vw;
            a 
            {
                position: relative;
            }
            a::after, div::after, .sub-nav-container p::after
            {
                @include effet;
            }
            a:hover::after, .sub-nav-container p:hover::after
            {
                @include effetHover;
            }
        }
        .btn-container 
        {
            margin-left: 3.2vw;
            .btn 
            {
                display: flex;
                align-items: center;
                
                padding: 0.3vw 0 0.3vw 1.3vw;
                
                border-radius: 6.5vw;
                font-size: 1.3vw;
                background-color: #018EBB;
                color: #FAF9F2;
                
                transition: transform 0.25s ease-in-out;
            }
        }
    }
}

.sub-nav 
{
    display: none; // devient display grid quand visible
    align-items: center;
    justify-content: space-evenly;
    grid-template-columns: 22.77vw 22.77vw 22.77vw 22.77vw;
    
    position: fixed;
    top: 0.5vw;

    z-index: 10;
    padding-left: 4vw;
    margin: 1.6vw;
    
    height: 17.5vw;
    width: 93vw;
    border-radius: 2.3vw;
    box-shadow: 0 0.13vw 1vw rgba(0, 0, 24, 0.07);
    background-color: #FAF9F2;

    transform: translateX(-49%);
    .sub-nav-element 
    {
        position: relative;
        &::after 
        {
            @include effet;
        }
        &:hover::after 
        {
            @include effetHover;
        }
        .content 
        {
            margin-top: 0.65vw;
            max-width:85%;

            line-height: 1.17vw;
            font-size: 0.8vw;
            color: #005570;
        }
        .arrow-effect 
        {
            position: relative;
            margin-left: 1.3vw;
            img
            {
                position: absolute;
                top: 50%;
                left: 53%;
                
                height: 0.9vw;
                transform: translate(-58%, -50%);
                transition: all 0.3s ease-in-out;
            }
            
        }
        &:hover
        {
            img:first-of-type
            {
                opacity: 0;
                transform: translate(0.06vw, -0.52vw);
            }
              
            img:last-of-type 
            {
                opacity: 1;
            }
        }
    }
}
.declencheur 
{
    cursor: pointer;

    display: flex;
    align-items: center;
    height: 3.2vw;
}
.declencheur:hover .sub-nav
{
    display: grid;
    position: absolute;
    z-index: 20;
}
.sub-nav-container 
{
    display: flex;
    align-items: center;

    position: relative;
    .arrow
    {
        display: inline-block;
        vertical-align: bottom;
        
        margin: 0 0 -0.32vw 0.8vw;
        height: 1vw;
        
        transform: rotate(45deg);
        transition: all 0.4s ease;
    }
    &:hover .arrow 
    {
        transform: rotate(90deg);
        margin-bottom: 0;
    }
}
