.ads
{

    .blueArrow
    {
        margin-left: 1vw;
        
        height: 1.1vw;
        transform: rotate(90deg);
    }
    .campagne, .strategie
    {
        strong
        {
            color: #018EBB;
        }
        position: relative;
        
        padding: 2vw 0;
        margin: 8vw auto;
        
        width: 75%;
        border-radius: 2.35vw;
        
        color: #Faf9f2;
        background-color: #000018;
        .simple-title 
        {
            margin: 0 0 1.5vw 1.5vw;
        
            font-weight: 500;
            font-size: 1.5vw;
        }
        .simple-content
        {
            margin: 0 1.8vw;
        
            text-align: justify;
            font-size: 1.2vw;
            line-height: 1.95vw;
        }
        .blueGrid 
        {
            left: -4.3vw;
            top: -0.3vw;
        
            transform: rotate(90deg);
        }
        .blueGrid, .greyGrid
        {
            position: absolute;
            z-index: -1;
        
            height: 5vw;
        }
    }
    .strategie 
    {
        margin-bottom: 10vw;
        
        box-shadow: -0.13vw 0.26vw 0.52vw rgba(0, 0, 0, 0.15);
        background-color: #faf9f2;
        color: black;
        .greyGrid 
        {
            bottom: -2.5vw;
            left: 50vw;
            &:nth-last-child(1) 
            {
                top: -2.5vw;
            }
        }
    }
    .ads-double 
    {
        display: flex;
        
        margin: auto;
        
        height: 24vw;
        width: 83%;
        border-radius: 2.35vw;
        box-shadow: -0.32vw 0.32vw 0px #018EBB;
        
        background-color: #E9E8E3;
        .double-element
        {
            margin: 0 3vw;
            .double-title
            {
                margin-top: 3vw;
        
                font-size: 1.7vw;
                font-weight: 500;
            }
            .double-content
            {
                margin-top: 3vw;
                max-width: 93%;
        
                text-align: justify;
                font-size: 1.3vw;
                line-height: 2vw;
            }
        }
    }
}   