.footer-container 
{
    position: relative;
    margin-bottom: 1.3vw;
    .footer-btn-container
    {
        position: relative;
        
        margin: 3.25vw 30%;
        width: 33%;
        .footer-btn
        {
            display: block;
            position: relative;

            margin:3.25vw auto 9.1vw auto;
            padding: 2.9vw 6.5vw 3.9vw 2.25vw;
            
            width: 100%;
            border-radius: 2.34vw;
            background-color: #FAF9F2;
            box-shadow: -0.26vw 0.26vw 0.65vw rgba(0, 0, 24, 0.07);

            transition: transform 0.3s ease-in-out;
            .arrow-effect
            {
                position: absolute;
                right: 1.3vw;
                bottom: 1.3vw;

                height: 2.7vw;
                width:2.7vw;
                border-radius: 100%;
                background-color: #000018;
                
                transition: all 0.3s ease-in-out;
                img
                {
                    position: absolute;
                    top: 50%;
                    left: 53%;

                    transform: translate(-50%, -50%);
                    transition: all 0.3s ease-in-out;
                }
                .arrow2
                {
                    opacity: 0;
                    transform: translate(-15px, -8px);
                }
            }
            &:hover
            {
                box-shadow: -0.26vw 0.26vw 0px #018EBB;
                transform: translate(0.2vw, -0.2vw);
                img:first-of-type
                {
                    opacity: 0;
                    transform: translate(0.06vw, -0.5vw);
                }
                img:last-of-type 
                {
                    opacity: 1;
                    transform: translate(-0.64vw, -0.5vw);
                }
            }
        }
        .grey-bg
        {
            position: absolute;
            left: 11%;
            top: 23%;

            z-index: -1;
            height: 100%;
            width: 125%;
            border-radius: 2.34vw;
            background-color: #E9E8E3;
        }
        .footer-title
        {
            margin-bottom: 0.65vw;

            font-size: 1.7vw;
            font-weight: 600;
        }
        .footer-content
        {
            font-size: 1.3vw;
        }
        .grey-grid 
        {
            position: absolute;
            left: -5.9vw;
            top: 0;

            z-index: -1;
            height:5vw;
            transform: rotate(90deg);
        }
    }
    .footer 
    {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        
        position: relative;

        margin: 0 auto;
        padding: 2vw 0;

        max-width: 91vw;
        width: calc(100vw - 11.3vw);
        height: 7.5vw;
        border-radius: 10vw;
        
        background-color: #000018;
        color: #F4F9F2;
        
        .footerContact p
        {
            margin-top: 1vw;
            font-size: 1.04vw;  
        }
        .footer-social
        {
            display: flex; 
            justify-content: space-between;
            
            margin: 1vw 0 1.5vw 0 ;
            width: 11vw;
            img
            {
                height: 1.62vw;
                transition: all 0.15s ease-in;
                &:hover 
                {
                    transform: scale(1.25);
                }
            }
        }

        .mention 
        {
            position: absolute;
            bottom: 1vw;

            width: fit-content;
            font-weight: 200;
            font-size: 0.9vw;
            a
            {
                color: #FAF9F2;
            }
        }
        .btn 
        {
            
            display: flex ;
            align-items: center;
            
            padding: 0.18vw 0 0.18vw 1.5vw;
            
            border-radius: 2.35vw;
            font-size: 1.4vw;
            font-weight: 400;

            color: #000018;
            background-color: white;
            
            transition: transform 0.25s ease-in-out;
            .arrow-effect 
            {
                display:inline-block;
                margin: 0 0.25vw 0 1.5vw;
             
                height: 2.5vw;
                width: 2.5vw;
                
                background-color: #000018;
                img 
                {
                    height: 1.1vw;
                }
            }  
            &:hover  img:last-of-type 
            {
                opacity: 1;
                transform: translate(-0.8vw, -0.55vw); 
            }
        }
    
    }
}