@media screen and (max-width: 768px) {  
    .agence
    {
        .header 
        {
            margin-left: 0;
            .title 
            {
                margin: 14vw 0 0 7.5vw;
                
                text-align: left;
                font-size: 5vw;
                line-height: 9vw;
                .underline 
                {
                    border-width:1vw ;
                }
            }
            .presentation 
            {
                position: relative;
                
                margin: 5vw;
                padding: 3.5vw;
                
                max-width: 90%;
                border-radius: 5vw;
                
                line-height: 4vw;
                font-size: 3vw;
                box-shadow: -0.5vw 0.6vw 2vw rgba(0, 0, 24, 0.15);
            }
        }
        .agence-illustration 
        {
            display: none;
        }
        .team 
        {
            margin: 12vw 10vw;
            padding: 5vw;

            max-width: 80vw;
            border-radius: 5vw;
            .title 
            {
                font-size: 4vw;
                line-height: 6vw;
                .underline 
                {
                    border-width:0.5vw ;
                }
            }
            .team-content
            {
                margin: 5vw 3.25vw 0 0;

                font-size: 3vw;
                line-height: 4vw;
                .blue 
                {
                    font-weight: 500;
                }
            }
            .blue-grid 
            {
                height: 10vw;
            }
        }
        .agence-solution-container 
        {
            position: relative;
            margin: 10vw;
            .solution-title
            {
                margin-bottom: 3.25vw;

                font-weight: 500;
                font-size: 3.3vw;
            }
            .solution-list 
            {
                display: flex;
                flex-wrap: wrap;
                justify-content: flex-start;
                
                margin-left: -10vw;
                padding-left:10vw;
                
                width: 90vw;
                .solution-element
                {
                    &:nth-child(2), &:nth-child(4), &:nth-child(6)
                    {
                        position:relative;        
                        top: 10vw;
                    }
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    text-align: left;

                    position: relative;

                    padding:0 5vw;
                    margin-right: 5%;
                    margin-top:3.5vw;

                    width: 33%;
                    height: 15vw;
                    box-shadow:10px 0px 27px rgba(0, 0, 0, 0.15);
                    border-bottom: 1vw solid #018EBB;
                    border-radius: 1vw;
                    
                    line-height:4vw;
                    font-size: 3.3vw;
                    list-style-type: none;
                    a 
                    {
                        display: block;
                    }
                    .specification 
                    {
                        display: none;
                    }
                    span 
                    {
                        position: absolute; 
                        top: 50%;
                        right: 10%;
                        img
                        {
                            top: 0;

                            margin-left: 1.5vw;
                            height: 2.8vw;
                        }
                    }
                }
                .responsive-element 
                {
                    position: relative;
                    br 
                    {
                        display: block;
                    }
                    span 
                    {
                        position: absolute; 
                        top: 50%;
                    }
                }
            }
            .grey-grid
            {
                display: none;
            }
            .solution-btn 
            {
                display: none;
            }
        }
        .why 
        {
            margin: 10vw 4vw;
            padding: 5vw 0 0.1vw 0;
            
            width: 92vw;
            height: fit-content;
            border-radius:5vw;
            .title
            {
                margin: 0 11vw;
                font-size: 4vw;
                .blue 
                {
                    border-width: 0.5vw !important;
                }
            }

            .why-content
            {
                flex-direction:column;
                justify-content: center;

                margin: 5vw 5vw 10vw 10vw;

                font-size: 3vw;
                line-height: 4.3vw;
                .left
                {
                    margin-left: 0 !important;
                    max-width: 100%;
                }
                .right
                {
                    margin-top: 5vw;
                    max-width: 100%;
                    ul 
                    {
                        margin: 0 2vw;
                        li 
                        {
                            margin-top:2vw ;
                            
                            color: #018EBB;
                            list-style-type: disc;
                            span 
                            {
                                color: #000018;
                            }
                        }
                    }
                }
            }
        }
    }
}