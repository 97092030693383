.dev-web 
{
    .e-com 
    {
        position: relative;

        margin: 7vw auto;
        padding-top:2vw ;

        width: 75%;
        height: 13vw;
        border-radius: 2.3vw;
        box-shadow: -2px 4px 8px rgba(0, 0, 0, 0.15);
        background-color: #faf9f2;
        .ecom-content
        {
            margin:0 2vw;

            text-align: justify;
            line-height: 1.95vw;
            font-size: 1.3vw;
        }
        .ecom-title
        {
            margin: 0 0 1.80vw 2vw;
            font-size: 1.8vw;
            font-weight:500 ;
            span img 
            {
                margin-left: 1vw;
                height: 1.2vw;
                transform: rotate(90deg);
            }
        }
        .blueGrid 
        {
            position: absolute;
            top: -0.4vw;
            left: -6vw;
            
            z-index: -1;
            transform: rotate(90deg);
        }
    }
    .web-content 
    {
        display: flex;
     
        margin-bottom: 12.5vw;
        height: 19vw;
        strong 
        {
            color: #018EBB;
        }
        .content-element 
        {
            display: flex;
            align-items: center;
            flex-direction: column;
           
            position: relative;
           
            margin: 0 auto;
           
            width: 40vw;
            border-radius: 2.3vw;
            box-shadow: -2px 4px 8px rgba(0, 0, 0, 0.3);
            background-color: #000018;
            .element-title
            {     
                margin: 1.75vw;

                text-align: center;
                font-size: 2vw;
                font-weight: 500;
                color:#faf9f2;
                .blueArrow 
                {
                    margin-left: 1vw;
                    height: 1.4vw;
                    transform: rotate(90deg);
                }
            }
            .element-content
            {
                margin: 0 3vw;
                
                line-height: 1.9vw;
                font-size: 1.1vw;
                color: #faf9f2;
                text-align: justify;
            }
        }
    }
}