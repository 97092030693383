@media screen and (max-width: 768px) {
    .project 
    {
        .projectHeader
        {
            margin-left: 0;
            .more 
            {
                margin: 2.27vw 0 0 10vw;
                font-size: 3vw;
            }
            .title 
            {
                margin: 14vw 0 0 10vw;
                
                text-align: left;
                line-height: 9vw;
                font-size: 5vw;
                .underline 
                {
                    border-width:1vw ;
                }
            }
            .citation 
            {
                margin: 4vw auto 4vw 10vw!important;
                
                max-width: 90% !important;
                
                text-align: left;
                font-size: 3.5vw !important;
                line-height: 5vw;
                br 
                {
                    display: none;
                }
            }
            .illustration 
            {
                display: none !important;
            }
        }
        .example-container
        {
            .sort-bar
            {
                margin-bottom: 7vw;
                height: 4vw;
                .active 
                {
                    background-color: #018EBB;
                    color: #FAF9F2;
                }
                .sort-element
                {
                    padding: 2vw;
                    margin-right:3vw;

                    font-size: 3vw;
                }
            }
            .card-container 
            {
                flex-direction:column ;
                align-items: center;
                justify-content: center;
             
                margin: 0 2.5vw;
                width: 95vw;
            }
        }
        .card 
        {
            margin: 3vw 0;

            height: 52vw;
            width: 93.6vw;
            border-radius: 8vw;
            .description 
            {
                display: none;
            }
            .responsive-description 
            {
                display: inline;
            
                position: relative;
                top: 0vw;
            
                margin: 0.5vw;
            
                font-size: 2.5vw;
                font-weight: 400;
                color: #018EBB;
            }
            .title-container 
            {
                left: 50%;
                bottom: 10%;
                transform: translateX(-50%);    

                padding: 3.4vw;
                
                width: 82.5%;
                border-radius: 5vw;
                border-bottom: 3.5px solid #018EBB;
                box-shadow: 0px 0px 15px rgba(0, 0, 24, 0.1);

                font-size: 2.6vw;
                .arrow-effect img
                {
                    height: 3vw;
                }
            }
        }
        .overlay 
        {
            top: 0;
            bottom: 0;
            left: 50%;
            
            z-index: 9999; 
            
            width: 100vw;
            height: 100vh;
            border-radius: 0;
            .overlay-container
            {
                height: 100%;
            }
            .first-bg
            {
                width: 100% ;
            }
            .second-bg 
            {
                display:flex; 
                align-items: center;
                flex-direction: column;
                
                margin-top: -1vw;
                
                height: fit-content;
                width: 100%;
                .text-part
                {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex: 0;

                    overflow: hidden;
                
                    padding: 20vw 0vw;
                
                    font-size: 3vw;
                    font-weight: 200;
                    p
                    {
                        padding: 0 10vw;
                    
                        text-align: justify;
                        line-height: 5vw;
                        span
                        {
                            font-size: 2.5vw; 
                            font-weight: 200;
                        }
                    }
                }
                .image-part 
                {
                    flex: 1;

                    width: 100%;
                    height: fit-content;
                }
            }
            .third-bg 
            {
                display: flex;
                flex-direction: column;
                height: fit-content;
                .left-img, .right-img
                {
                    width: 100%;
                }
                .rectangle-container
                {
                    height: 100%;
                    width: 50%;
                    .first-rec
                    {
                        height: 50%;
                        width: 100%;
                    }
                    .second-rec
                    {
                        height: 50%;
                        width: 100%;
                    }
                }
            }
            .back, .prev
            {
                height: 15vw !important;
                width: 15vw !important;
                img
                {
                    height: 5vw;
                }
                &:hover
                {
                    img:first-of-type
                    {
                        opacity: 1;
                        transform: translate(-50%, -50%) rotate(180deg);
                    }
                    img:last-of-type 
                    {
                        opacity: 0;
                    }
                }
            }
            .back
            {
                top: 7.5vw;
                left: 5vw;
            }
            .moveProject 
            {
                display: none;
            }
        }
    }

}   