.contact-form 
{
  display: flex;
  flex-direction: column;
  
  position: relative;

  margin: 4vw 0 10vw 10.3vw;
  padding: 2.35vw;
  
  height: 37vw;
  width: 42vw;
  border-radius: 2.35vw;
  background-color: #000018;
  .double-row,
  .simple-row
  {
    display: flex;
  
    margin-bottom: 1vw;  
    width: 100%;
    height: fit-content;
    .form-input
    {
      &:nth-child(2)
      {
        margin-left:1vw ;
      }
      display: flex;
      flex: 1;

      height: fit-content;
    }
  }

  .btn 
  {
    display: flex;
    align-items: center;
    
    position: relative;
    left: -2vw;
    
    padding: 0.25vw 0 0.25vw 1.3vw;
    
    height: 4vw;
    
    width: fit-content;
    font-size: 1.3vw;
    border-radius: 6.5vw;
    
    font-family: 'Poppins';
    color: #FAF9F2;
    background-color: #000018;
    transition: transform 0.3s ease-in-out;
    
    margin-top: 3vw;

    outline: none;
    border: none;
    cursor: pointer;

    .arrow-effect 
    {
      margin-right: 0.3vw;
    }
  }
  .btn:hover
  {
    transform: translate(0.2vw, -0.2vw);
    box-shadow: 0 0.26vw 0.52vw rgba(0, 0, 0, 0.15);
  }
  .blueGrid 
  {
    position: absolute;
    top: 0.7vw;
    left: -6.5vw;

    z-index: -1;
    height: 5vw;
    
    transform: rotate(90deg);
  }
  .greyGrid 
  {
    position: absolute;
    top: 14.5vw;
    right: -6.5vw;
    
    z-index: -1;
    height: 5vw;
    
    transform: rotate(90deg);
  }
}