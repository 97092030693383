@media screen and (max-width: 768px) {
    .btn:hover
    {
        transform: translate(0, 0);
        box-shadow: 0 0 0 rgba(0, 0, 0, 0.15);
        img:first-of-type
        {
            opacity: 1;
            transform: translate(-1vw, -1.5vw);
        }
          
        img:last-of-type 
        {
            opacity: 0;
            transform: translate(-0, -0);
        }
          
    }  
}