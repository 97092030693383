.contact
{
    .contact-title 
    {
        margin: 9vw 0 0 10.3vw;

        font-size: 3.1vw;
        font-weight: 700;
    }
    .contact-subtitle
    {
        margin: 1vw 0 0 10.3vw;
        font-size: 1.3vw;
        .blueArrow 
        {
            position: relative;
            top: 0.15vw;

            margin-left: 0.65vw;
            
            height: 1vw;
            transform: rotate(90deg);
        }
    }
    .bubble-illu-container 
    {
        position: absolute;
        right: 16.5vw;
        top: 17vw;
        img 
        {
            height: 15.3vw;
        }
        .bubble-content
        {
            position: relative;
            top: -12.46vw;
            left: 3.54vw;

            line-height: 2.3vw;
            font-size: 1.56vw;
            font-weight: 700;
            .bubble-underline 
            {
                border-bottom: 0.2vw solid #000018;
            }
            .white
            {
                color: #faf9f2;
            }
        }
    }
    .btn-section 
    {
        display: flex;
        flex-direction: column;

        position: absolute;
        right: 11vw;
        top: 44.5vw;
        .btn 
        {
            display: flex;
            align-items: center;

            position: relative;

            padding: 0.30vw 0 0.30vw 1.3vw;
            margin-bottom: 1.62vw;

            width: fit-content;
            border: none;
            border-radius: 2.35vw;

            font-size: 1.3vw;
            font-family: 'poppins';
            color: #faf9f2;
            background-color: #000018;

            transition: all 0.3s ease-in-out;
            .arrow-effect
            {
                display: block;
            
                margin-right: 0.3vw;
                background-color: white !important;
            }
        }
        .blueGrid 
        {
            position: absolute;
            bottom: -2vw;
            right: 0.6vw;
            
            z-index: -1;
            height: 5vw;
        }
    }
    .grey-background
    {
        position: absolute;
        left: 0;
        right: 0;
        top: 42vw;
     
        z-index: -1;
        height: 37vw;
        border-radius: 4.55vw;
        
        background-color: #E9E8E3;
    }
    .footer-container
    {
        .footer-btn, .grey-grid
        {
            display: none !important;
        }
    }
}