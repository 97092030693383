@media screen and (max-width: 768px) {
    .social 
    {
        .social-grid 
        {
            flex-wrap: wrap;
            justify-content: center;
            
            margin: 10vw auto;
            width: 95vw;
            .grid-element 
            {
                margin: 3vw;
                padding: 3vw;
            
                width: 100%;
                height: fit-content;
                border-radius: 2.3vw;
                box-shadow: 0px 4px 8px rgba(0, 0, 24, 0.1);
            
                background-color: #FAF9F2;
                .blueGrid, .greyGrid
                {
                    display: none;
                }
                .element-title
                {
                    margin-bottom: 1.80vw;
            
                    font-size: 4vw;
                    font-weight:500 ;
                    span img 
                    {
                        margin-left: 2vw;
                        
                        height: 3vw;
                        transform: rotate(90deg);
                    }
                }
                .content
                {
                    text-align: justify;
                    line-height: 4vw;
                    font-size: 3vw;
                }
            }
        }
    }
}