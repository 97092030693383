@import "../../../App.scss";

    .arrow-effect
    {
        position: relative;

        margin: 0 0.3vw 0 0.9vw;
        
        height: 2.1vw;
        width:2.1vw;
        border-radius: 100%;
        transition: all 0.3s ease-in-out;
        img
        {
            position: absolute;
            top: 50%;
            left: 55%;
        
            height:1vw;

            transform: translate(-50%, -50%);
            transition: all 0.3s ease-in-out;
        }
        img:last-of-type  
        {
            opacity: 0;
            transform: translate(-0.98vw, -0.5vw);
        }
          
    }
    .btn:hover
    {
        transform: translate(0.2vw, -0.2vw);
        box-shadow: 0 0.25vw 0.52vw rgba(0, 0, 0, 0.15);
        img:first-of-type
        {
            opacity: 0;
            transform: translate(0.06vw, -0.5vw);
        }
        img:last-of-type 
        {
            opacity: 1;
            transform: translate(-0.64vw, -0.5vw);
        }
}
