.homeHeader 
{
    position: relative;

    margin-left: 10.5vw;
    height: 54vw;
    .btn 
    {
        display: flex;
        align-items: center;

        padding: 0.3vw 0 0.3vw 1.3vw;

        width: 14.5vw;
        border-radius: 100px;

        font-size: 1.232vw;
        color: #FAF9F2;
        background-color: #000018;
        transition: transform 0.3s ease-in-out;
    }
    .homeCitation
    {
        margin: 2.6vw 0;
     
        font-size: 2.15vw;
        font-weight: 400;
        font-style: italic;
    }
    .description 
    {
        margin-bottom: 4.5vw;
     
        max-width: 50%;
        font-size: 1.3vw;
        line-height: 2vw;
    }
    .headerImage 
    {
        position: absolute;
        bottom: 7.5%;
        right: 13%;
     
        z-index: -1;
        height: 36vw;
    }
}