.comingSoon 
{
    .comingImage 
    {
        width: 100%;
        z-index: -1;
    }
    .responsiveComingSoon
    {
        display: none;
    }
    .comingBtn
    {
        display: flex;
        align-items: center;

        position: absolute;
        top: 31vw;
        left: 51vw;
        
        padding: 0.3vw 0 0.3vw 1.3vw;
        border-radius: 6.5vw;
        
        font-size: 1.3vw;
        color: #FAF9F2;
        background-color: #000018;

        transition: transform 0.3s ease-in-out;
        &:nth-child(3)
        {
            top: 35vw;
        }
    }
    .footer-container
    {
        margin-top: -3.5vw;
    }
}