@media screen and (max-width: 768px) {
    .sub-nav-header 
    {
        margin-left: 0;
        .title
        {
            margin: 13vw 0 0 10vw;
            
            line-height: 9vw;
            font-size: 5vw;
            text-align: left;
            span:first-of-type
            {
                border-width: 0.75vw !important;
            }
        }
        .citation 
        {
            margin:7vw auto 7vw 10vw!important;

            max-width: 85% !important;
            line-height: 5vw;
            text-align: left;
            font-size: 3vw !important;
            br 
            {
                display: none !important;
            }
        }
        .header-img 
        {
            display: none !important;
        }
        .sub-content
        {
            margin: auto;
            padding: 5vw;

            max-width: 80vw;
            height:fit-content;
            border-radius: 5vw;
            .sub-description 
            {
                margin-top: 4vw;
                line-height: 5vw;
                font-size: 3vw !important;
            }
        }
    }
    .title-container
    {
        h1
        {
            font-size: 4vw !important;
        }
        .blueArrow 
        {
            margin-left: 3vw;
            
            height: 4vw;
            transform: rotate(90deg);
        }   
    }   
}

