@media screen and (max-width:768px) {
    .seo 
    {
        .seo-title
        {
            margin-bottom: 4vw;
            font-size: 4vw;
            span img 
            {
                margin-left: 2vw;
                height: 3vw;
            }
        }
        .keywords 
        {
            margin: 10vw auto 15vw;
            width: 90%;
            .keywords-content
            {
                max-width: 100%;

                line-height: 4vw;
                font-size: 3vw;
            }
            h1 
            {
                margin-bottom: 5vw;
                font-size: 4vw;
            }
            .greyGrid
            {
                display: none;
            }
        }
        .quality 
        {
            margin: auto;
            padding: 5vw 0 2vw 0;
            width: 90%;
            height: fit-content;
            border-radius: 5vw;
            .quality-title 
            {
                margin: 0 5vw;
            }
            .quality-content 
            {
                margin: 5vw;

                text-align: justify;
                line-height: 4.5vw;
                font-size: 3vw;
            }
            .blueGrid 
            {
                position: absolute;
                z-index: -1;
                
                height: 9vw;
            }
            .blueGrid-top
            {
                top: -4.5vw;
                left: 15vw;
            }
            .blueGrid-bot 
            {
                bottom: -4.5vw;
                left: 15vw; 
            }
        }
        .suivie 
        {
            margin-top: 20vw;
            .suivie-content
            {
                display: flex;
                flex-direction: column;

                margin: auto auto 7.5vw auto;
                
                width: 95%;
                height: fit-content;
                .suivie-element 
                {
                    display: flex;
                    flex-direction: column;
                    align-items: center;

                    padding: 5vw 3vw;

                    height: fit-content;
                    width: 84vw;
                    border-radius: 5vw;
                    box-shadow: -0.13vw 0.26vw 0.52vw rgba(0, 0, 0, 0.25);
                    
                    line-height: 4vw;
                    font-size: 3vw;
                    &:first-child
                    {
                        margin-bottom: 10vw;
                    }
                }
            }
        }
    }
}