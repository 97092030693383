.agence-solution-container 
{
    position: relative;
    margin: 12.55vw 17.9vw 4vw 17.9vw;

    .solution-title
    {
        margin-bottom: 3.25vw;

        font-weight: 500;
        font-size: 1.3vw;
    }
    .solution-list 
    {
        margin-left: 3.25vw;
        .solution-element
        {
            margin-top:0.8vw;

            color: #018EBB;
            font-size: 1.3vw;
            list-style-type: disc;
            span 
            {
                font-size: 1vw;
            }
            &:hover .btn
            {
                box-shadow: none;
            }
        }

    }
    .grey-grid
    {
        position: absolute;
        right: 5vw;
        top: 0;
    }
    .responsive-element br
    {
        display: none;
    }
    .solution-btn 
    {
        cursor: pointer;
        display: flex;
        align-items: center;
        
        padding-left:1.3vw ;
        margin-top:4vw;

        width: 21.8vw;
        height: 2.6vw;
        border-radius: 6.25vw;

        font-size: 1.3vw;
        background-color: #018EBB;
        color: #Faf9f2;

        transition: transform 0.3s ease-in-out;
        .arrow-effect
        {
            margin-left: 1.2vw;
        }
    }
}