.seo 
{
    .seo-title
    {
        margin-bottom: 4vw;
     
        text-align: center;
        font-size: 1.8vw;
        font-weight:500 ;
        span img 
        {
            margin-left: 1vw;
     
            height: 1.2vw;
            transform: rotate(90deg);
        }
    }
    .keywords 
    {
        position: relative;
        margin: 7.5vw 10.5vw;
        .keywords-content
        {
            margin-top: 2.1vw;
     
            max-width: 85%;
     
            text-align: justify;
            font-size: 1.3vw;
            line-height: 1.95vw;
        }
        h1 
        {
            margin-bottom: 0;
     
            text-align: left;
            font-size: 2.1vw;
        }
        .greyGrid
        {
            position: absolute;
            top: -0.35vw;
     
            z-index: -1;
     
            height: 5vw;
            transform: rotate(90deg) translateY(137%);
        }
    }
    .quality 
    {
        position: relative;
        
        padding-top:2vw;
        margin: auto;
        
        width: 75%;
        height: 13vw;
        border-radius: 2.35vw;
        
        background-color: #000018;
        color: #faf9f2;
        .quality-title 
        {
            margin: 0 2vw;
            text-align: left;
        }
        .quality-content 
        {
            strong 
            {
                color: #018EBB;
            }
            margin: 2vw;

            font-size: 1.2vw;
            line-height: 1.7vw;
        }
        .blueGrid 
        {
            position: absolute;
            z-index: -1;
            
            height: 5vw;
        }
        .blueGrid-top
        {
            top: -3vw;
            left: 5vw;
        }
        .blueGrid-bot 
        {
            bottom: -3vw;
            left: 5vw; 
        }
    }
}
.suivie 
{
    margin: 10vw 0;
    .suivie-content
    {
        display: flex;
        justify-content: center;
        .suivie-element 
        {
            position: relative;
            
            margin: 0 3vw;
            padding: 1.5vw 2.5vw 0;
            
            width: 33%;
            height: 12.5vw;
            border-radius: 1.75vw;
            box-shadow: -0.13vw 0.26vw 0.52vw rgba(0, 0, 0, 0.15);
            
            text-align: justify;
            line-height: 1.8vw;
            font-size: 1.1vw;
            background-color: #faf9f2;
        }
    }
}