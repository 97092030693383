@media screen and (max-width: 768px) {
    .dev-web 
    {
        .title 
        {
            br 
            {
                display: none;
            }
        }
        .e-com 
        {
            margin: 10vw auto;
            padding:5vw 3vw;

            width: 84vw;
            height: fit-content;
            border-radius:5vw;
            .ecom-content
            {
                margin:0 2vw;

                text-align: justify;
                line-height: 4vw;
                font-size: 2.5vw;
            }
            .ecom-title
            {
                margin-bottom: 4vw;
                
                font-size: 4vw;
                font-weight:500 ;
                span img 
                {
                    margin-left: 2vw;
                    height: 3vw;
                }
            }
            .blueGrid 
            {
                display: none;
            }
        }
        .web-content 
        {
            display: flex;
            flex-direction: column;
            
            margin-bottom: 12vw;
            height: fit-content;
            .content-element 
            {
                padding: 5vw;
                margin-bottom: 10vw;
            
                width: 80vw;
                border-radius: 5vw;
                .element-title
                {        
                    margin-bottom: 5vw;
                    font-size: 4vw;
                    .blueArrow 
                    {
                        margin-left: 3vw;
                        height: 3.5vw;
                    }
                }
                .element-content
                {
                    margin: 0;
                    
                    font-size: 3vw;
                    line-height: 5vw;
                }
            }
        }
    }
}