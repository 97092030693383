.hidden
{
    display: none;
}
.overlay {
    position: fixed;
    top: 12%;
    left: 50%;
    bottom: 11%;

    overflow-x: hidden;
    z-index: 9999; 
    
    width: 90vw;
    height: 42.5vw;
    border-radius: 3vw;
    transform: translateX(-50%); 
    .overlay-container
    {
        overflow-y: scroll;
        overflow-x: hidden;
        height: 100%;
    }
    .first-bg
    {
        width: 100%;
    }
    .second-bg 
    {
        display: flex;
        align-items: center;
     
        margin-top: -0.2vw;
     
        height: 45vw;
        width: 100%;
        .text-part
        {
            display: flex;
            justify-content: center;
            align-items: center;
            flex: 1;
        
            overflow: hidden;
            height: 100%;
        
            font-size: 1vw;
            font-weight: 200;
            p
            {
                padding: 0 6vw;
                text-align: justify;
                line-height: 1.5vw;
                span
                {
                    font-weight: 200 ;
                    font-size: 0.8vw; 
                }
            }
        }
        .image-part 
        {
            flex: 1;
            height: 100%;
        }
    }
    .third-bg 
    {
        display: flex;
        height: 45vw;
        .left-img, .right-img
        {
            height: 100%;
            width: 45vw;
        }
        .rectangle-container
        {
            height: 100%;
            width: 50%;
            .first-rec
            {
                height: 50%;
                width: 100%;
            }
            .second-rec
            {
                height: 50%;
                width: 100%;
            }
        }
    }
    .back, .prev
    {
        cursor: pointer;
        z-index: 9999;
     
        height: 4vw;
        width: 4vw;
        
        border-radius: 100%;
        
        transition: all 0.3s ease-in-out;
        background-color: #000018;
        img
        {
            position: absolute;
            left: 50%;
            top: 50%;

            height: 1.4vw;
            transform: translate(-50%, -50%) rotate(180deg);
            transition: all 0.35s ease;
        }
        img:last-of-type  
        {
            opacity: 0;
            transform: translate(0.1vw, -0.75vw);
        }
        &:hover
        {
            transform: translate(-0.2vw, -0.2vw);
            box-shadow: 0 0.25vw 0.52vw rgba(0, 0, 0, 0.15);
            img:first-of-type
            {
                opacity: 0;
                transform: translate(0.06vw, -0.5vw) rotate(180deg);
            }
            img:last-of-type 
            {
                opacity: 1;
                transform: translate(-0.75vw, -0.65vw);
            }
        }
    }
    .back 
    {
        position: absolute;
        top: 3vw;
        left: 3vw;
    }
    .moveProject 
    {
        display: flex;

        position: absolute;
        bottom: 1.5vw;
        right: 3vw;
        .prev 
        {
            position: relative;
            img:first-of-type
            {
                transform: translate(-50%, -50%) rotate(180deg); 
            }
        }
        .next 
        {
            cursor: pointer;
            position: relative;
            
            margin-left: 0.2vw;
        
            height: 4vw;
            width: 4vw;
            border-radius: 100%;
    
            background-color: #000018;
            transition: all 0.35s ease;
            span.arrow-effect
            {
                left: 30%;
                top: 35%;
    
                height: 4vw;
                width: 4vw;
                img 
                {
                    height: 1.4vw;
                }
            }
            img:first-of-type
            {
                transform: translate(-50%, -45%);
            }
            img:last-of-type 
            {
                transform: translate(-100%, -0.75vw);
                font-weight:400;
            }
            &:hover
            {
                img:last-of-type 
                {
                    transition: all 0.3s ease;

                    transform: translate(-0.95vw, -0.60vw);
                    font-weight:400;
                }
            }
        }   
    }
}