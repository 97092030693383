.who-container 
{
    position:relative ;
    top: -6vw;
    
    margin-left: 12vw;
    padding: 1.6vw 2vw;
    
    width: 41vw;
    height: 24vw;
    border-radius: 2.3vw;
    
    color: #F4F9F2;
    background-color: #000018;
    .who-title 
    {
        font-size: 2.6vw;
        font-weight: 650;
    }
    .who-subtitle
    {
        margin: 1.3vw 0 3.9vw 0;

        font-size: 1.3vw;
        font-weight: 600;
        color: #018EBB;
    }
    .who-description
    {
        line-height: 2vw;
        font-size: 1.3vw;
    }
    .who-illustration 
    {
        position: absolute;
        top: -2.5vw;
        right:2.5vw;

        height: 135%;
        transform: translateX(98%) translateY(-10%);
    }
    .who-link 
    {
        display: flex;
        align-items: center;

        margin-top: 4.5vw;
        font-size: 1vw;
        color: #F4F9F2;
        strong 
        {
            font-weight: 600;
        }
        .arrow-effect
        {
            position: relative;
        
            margin-left: 0;
            transition: all 0.3s ease-in-out;
            img
            {
                position: absolute;
                left: 50%;
                top: 50%;
        
                height: 1vw;
                transform: translate(-50%,-50%);
                transition: all 0.25s ease-in-out;
            }
        }   
        &:hover
        {
            img:first-of-type
            {
                opacity: 0;
                transform: translate(0.07vw, -0.5vw);
            }
                
            img:last-of-type 
            {
                opacity: 1;
                transform: translate(-0.64vw, -0.5vw);
            }
        }     
    }
}