@media screen and (max-width: 768px) {
    .video 
    {
        .sub-part
        {
            flex-direction: column;
            
            padding:3.5vw;
            margin-top: 10vw;
            
            height: 150vw;
            border-radius: 6vw;
            border-bottom: 1.5vw solid #018EBB;
            border-left: 1vw solid #018EBB;
            .social-network 
            {
                .sub-title
                {
                    
                    margin-bottom: 2vw;
                    font-size: 4vw;
                    span img 
                    {
                        margin-left: 3vw;
                        height: 3vw;
                    }
                }
                .content 
                {
                    line-height: 4vw;
                    font-size: 3vw;
                }
            }
        }
        .grey-grid 
        {
            display: none;
        }
        .description-container 
        {
            padding: 5vw 2.5vw !important;
            margin: 10vw auto;
            margin-bottom: 10vw !important;
        
            height: 40vw;
            width: 90vw;
            box-shadow: -0.5vw 0.5vw 2vw rgba(0, 0, 0, 0.15);
            border-radius: 10vw;
            
            background-color: #FAF9F2;
            h2 
            {
                line-height: 5vw;
                font-size: 3.5vw;
                .responsive-arrow 
                {
                    display: inline-block;
                    
                    position: relative; 
                    top: 1vw;
                    
                    margin-left: 3vw;
                    
                    height: 4vw;
                    transform: rotate(90deg);
                }
            }
            p 
            {
                margin-top: 6vw;
                
                line-height: 4vw;
                font-size: 3vw;
            }
        }
    }
}