@media screen and (max-width: 768px) {
    .footer-container 
    {
        position: relative;
        bottom: -2vw;
        .btn 
        {
            display: flex;
            align-items: center;

            position: relative;

            margin:auto auto 10vw auto;
            padding: 0 0.5vw 0 3vw;
            
            height: 10vw;
            width: fit-content;
            border: 1px solid #000018;
            border-radius: 5vw;

            font-size: 3vw;
            .arrow-effect 
            {
                margin-left: 2vw;
                height: 8vw;
                width: 8vw;
                img 
                {
                    height: 3vw;
                }
            }
        }
        .footer 
        {
            padding: 2vw 0;

            max-width: 100% !important;
            width: 100vw !important;
            height: 25vw !important;
            border-radius: 10vw 10vw 0 0 !important;
            
            .footer-social
            {
                width: 35vw !important;
                img
                {
                    height: 4.5vw !important;
                }
            }
    
            .mention 
            {
                bottom: 2vw !important;
                font-size: 2.5vw !important;
            }
            .btn 
            {
               display: none !important;
            }   
        }
    }
}

