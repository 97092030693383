.reveal div:has(.card)
{
    display: flex;
}
.card 
{
    cursor: pointer;
    position: relative;

    overflow: hidden;
    margin: 2.1vw;
    
    height: 20vw;
    width: 36vw;
    border-radius: 2.34vw;
    box-shadow: 0 0 25px rgba(0, 0, 24, 0.15);
    
    background-color: #faf9f2;
    .bgimg 
    {
        filter: blur(0px);
        z-index: -2;

        height: 100%;
        width: 100%;
        transition: all 0.3s ease-in-out;
    }
    .responsive-description 
    {
        display: none !important;
    }
    .description 
    {
        position: absolute;
        top: 65%;
        left: 10%;
        
        opacity: 0;
        
        font-size: 1.3vw;
        transform: translateY(4.55vw);
        transition: all 0.3s ease-in-out;
        color: #018EBB;
    }
    .title-container 
    {
        display: flex;
        align-items: center;
        justify-content: space-between;

        position: absolute;
        left: 50%;
        bottom: 10%;
        transform: translateX(-50%);    
        
        width: 82.5%;
        padding: 1.5vw 1.8vw 1.5vw 2.25vw;
        border-radius: 2.34vw;
        border-bottom: 4px solid #018EBB;
        box-shadow: 0px 0px 15px rgba(0, 0, 24, 0.1);
        
        background-color: #FAF9F2;
        font-size: 2.6vw;
        font-weight: 600;

        transition: all 0.3s ease-in-out;
        .arrow-effect img
        {
            height: 1.3vw;
        }
    }
    &:hover
    {
        .bgimg 
        {
            filter: blur(0.2vw);
        }
        .description 
        {
            opacity: 1;
            transform: translateY(0px);
        }
        .title-container 
        {
            padding-bottom: 5.8vw;
        }
        .arrow-effect img:first-of-type
        {
            opacity: 0;
            transform: translate(0.06vw, -0.5vw);
        }
        .arrow-effect img:last-of-type 
        {
            opacity: 1;
            transform: translate(-0.64vw, -0.5vw);
        }
    }
}