@media screen and (max-width: 768px) {
    .contact
    {
        overflow: hidden;
        
        margin-right: 0 !important;
        padding-top: 2vw;
    
        width: 100vw !important;
        .contact-title 
        {
            margin: 0 0 0 10.3vw;
    
            font-size: 4vw;
            font-weight: 700;
        }
        .contact-subtitle
        {
            margin: 3vw 0 0 10.3vw;
            font-size: 2.5vw;
            .blueArrow 
            {
                margin-left: 1vw;
                height: 2vw;
            }
        }
        .bubble-illu-container 
        {
            position: absolute;
            left:7.5vw;
            top: 63.5vw;
            img 
            {
                display:none;
            }
            .white 
            {
                border-bottom: 0.2vw solid #faf9f2;
            }
            .bubble-content
            {
                br
                {
                    display:none;
                }
                position: relative;
                top: -12.46vw;
                left: 3.54vw;

                line-height: 2.3vw;
                font-size: 3vw;
                font-weight: 400;
                color:white;
                .white
                {
                    text-transform:lowercase;
                }
                .bubble-underline 
                {
                    border-bottom: none;
                }
            }
        }
        .btn-section 
        {
            display: none; 
        }
        .grey-background
        {
            position: absolute;
            left: 0;
            right: 0;
            top: 42vw;
            
            z-index: -1;
            height: 37vw;
            
            border-radius: 4.55vw;
            background-color: #E9E8E3;
        }
        .contact-form 
        {
            display: flex;
            flex-direction: column;

            padding: 12.5vw 5vw 0!important;
            margin: 5vw 0 20vw 5vw!important;

            height: 105vw !important;
            width: 80vw !important;
            border-radius: 5vw !important;
            .double-row,
            .simple-row
            {
                display: flex;

                margin-bottom: 1vw;
                
                width: 100%;
                height: fit-content;
            }
            .double-row
            {
                .form-input:nth-child(2)
                {
                    margin-left:3vw !important;
                }
            }
            .double-row:nth-child(2)
            {
                flex-direction: column;
                .form-input:nth-child(2)
                {
                    margin-left:0vw !important;
                }
            }
            .form-input
            {
                .form-label
                {
                    margin: 6.5vw 3vw !important;

                    width:100% !important;
                    
                    font-size: 3vw;
                    font-weight:500 ;
                }
                &:hover .form-label 
                {
                    display:none;
                }
                .shrink
                {
                    display: none !important;
                }
                input,
                textarea
                {
                    padding: 3vw !important;
                    margin-top: 3vw;
                    
                    height: 5vw;
                    font-size:3vw !important;
                }
                textarea
                {
                    height: 30vw;
                }
            }
            .btn 
            {
                top: 7.5vw;
                
                padding: 3.2vw 0.4vw 3.2vw 2vw !important;
                
                height: 5vw !important;
                font-size: 3vw !important;
                .arrow-effect 
                {
                    margin-left: 1vw !important;

                    height:5vw !important;
                    width:5vw !important;
                    img 
                    {
                        height: 3vw;
                    }
                }
            }
            .blueGrid 
            {
                display: none;
            }
            .greyGrid 
            {
                display: none;
            }
        }
    }
}