@media screen and (max-width: 768px) {
    .graphisme 
    {
        .sub-part 
        {
            margin-top: 15vw;
            margin-left: 10vw;
            width: 95%;
            .content 
            {
                font-size: 3vw;
                line-height: 4.5vw;
            }
        }
        .grey-grid 
        {
            left: 2vw;
            top: -1.5vw !important;

            height: 10vw !important;
        }
    }
    .description-container
    {
        flex-direction: column;
        
        margin-bottom: 0 !important;
        padding: 6vw !important;
        
        width: 80%;
        border-radius: 10vw !important; 
        .left
        {
            margin-right: 0 !important;
            margin-bottom: 6vw !important;
        }
        .description 
        {
            margin-top: 3vw !important;

            line-height: 4vw !important;
            font-size: 3vw !important;
        }
        .blueGrid
        {
            display: none;
        }
    }    
}