@media screen and (max-width: 768px) {
    .homeHeader
    {
        margin-left: 0 !important;

        height: fit-content !important;
        .headerImage 
        {
            display: none !important;
        }
        .title 
        {
            margin: 8vw 0 0 10vw;

            text-align: left;
            line-height: 9vw;
            font-size: 5vw;
            .underline 
            {
                border-width:1vw ;
            }
        }
        .homeCitation 
        {
            margin: 7vw auto 7vw 10vw !important;
            
            text-align: left;
            font-size: 3.5vw !important;
            line-height: 5vw;
        }
        .description 
        {
            margin-left: 10vw ;

            max-width: 80% !important;
            
            text-align: left;
            font-size: 3vw !important;
            line-height: 6vw !important;
        }
        .btn 
        {
            display: none !important;
        }
    } 
    .mobile-discover-container
    {
        flex-direction: column;

        margin: 15vw 0 0 10vw;
        z-index: 1000;
        .btn 
        {
            display: flex;
            align-items: center;

            padding: 1vw 0 1vw 3vw;
            margin-bottom: 3vw;
            
            width: fit-content;
            border-radius: 6.5vw;
            
            font-size: 4vw;
            transition: transform 0.25s ease-in-out;
            color: #FAF9F2;
            background-color: #018EBB;
            .arrow-effect 
            {
                margin: 0 0.75vw 0 2vw;

                height:7vw;
                width:7vw;
                img 
                {
                    height: 3.25vw;
                }
            }
        }
    }
    .solution-container 
    {
        top: 10vw !important;
        .top-container 
        {
            padding-bottom: 40vw !important;

            z-index: -1;
            border-radius: 10vw 10vw 0 0 !important; 
            .solution-title 
            {
                margin-left: 7vw;
                font-size: 7vw !important;
            }
            .solution-subtitle 
            {
                margin: 3.5vw 7vw !important;
                font-size: 5vw !important;
            }
            .solution-citation 
            {
                margin-left: 7vw;
                max-width: 80% !important;

                font-size: 4vw ;
                line-height: 5.5vw !important;
            }
        }
    }
    .bubble-container 
    {
        flex-direction: column;
        top: 0 !important;
        .blue-grid, .grey-grid
        {
            display: none;
        }
        .reveal div
        {   
            flex-direction: column;
        }
        .bubble
        {   
            margin: 4vw auto !important;

            width: 90% !important;
            height: 45vw !important;
            border-radius: 10vw !important;
            box-shadow: 0px 4px 8px rgba(0, 0, 24, 0.2) !important;
            .bubble-link
            {
                height: 40vw !important;
                width: 100% !important;
                border-radius: 10vw !important;
            }
            .bubble-title
            {
                margin: 10vw 0 7.5vw 10vw !important;
                font-size: 6vw !important;
                &::after 
                {
                    bottom: -1.8vw !important;
                    height: 0.9vw !important;
                }
            }
            .bubble-content 
            {
                margin: 0 10vw !important;
                font-size: 3.5vw !important;
                line-height: 4vw !important;
            }
            .arrow-effect
            {
                right: 5vw !important;
                top: 5vw !important;
             
                height: 12.5vw !important;
                width: 12.5vw !important;
                img
                {
                    height: 4vw;
                }   
            }
        }
    }
    .who-container 
    {
        position: relative;
        top: 10vw !important;

        margin: 0 auto 15vw !important;
        margin-bottom: 15vw !important;
        
        width: 90% !important;
        height: 70vw !important;
        border-radius: 5vw !important;
        .who-title 
        {
            margin: 5vw 5vw 0vw 5vw !important;
            font-size: 6vw !important;
        }
        .who-subtitle 
        {
            margin: 3vw 5vw !important;
            font-size: 3.5vw !important;
        }
        .who-description  
        {
            margin: 5vw;
            font-size: 3vw !important;
            line-height: 4.5vw !important;
        }
        .who-illustration 
        {
            display: none;
        }
        .who-link 
        {
            margin: 5vw !important;
            font-size: 3vw !important;
            img 
            {
                margin-left: 1.5vw !important;
                height: 3vw !important;
            }
            &:hover
            {
                img:first-of-type
                {
                    opacity: 1!important;
                    transform:translate(-0.85vw, -1.5vw)!important;
                }
                img:last-of-type 
                {
                    opacity: 0 !important;
                    transform: translate(-0.64vw, -0.5vw);
                } 
            }  
        }
    }
}