.solution-container 
{
    position: relative;
    top: -6.5vw;
    margin: 0 !important;
    z-index: -1;
    font-size: 1.3vw;
    .top-container 
    {
        padding:16.25vw 0 0 9.75vw;
    
        height: 26.75vw;
        border-radius: 4.5vw;

        background-color: #e9e8e3;
        .solution-title
        {
            font-size: 2.6vw;
            font-weight: 700;
        }
        .solution-subtitle 
        {
            margin: 1vw 0 1.6vw 0;
            font-weight: 700;
            color: #018EBB;
        }
        .solution-citation
        {
            max-width: 50%;

            line-height: 2vw;
            font-style: italic;
        }
    }
}
.bubble-container 
{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    
    position: relative;
    top: -15vw;
    .reveal div
    {
        display: flex;
    }
    .hide 
    {
        display: none;
    }
    .blue-grid
    {
        position: absolute;
        right: -1.75vw;
        top: -2.4vw;
        
        z-index: -1;
        height: 4.8vw;
    }

    .bubble
    {
        position: relative;
        
        margin: 1.6vw 3.25vw;
        
        height: 12vw;
        width: 21.5vw;
        border-radius: 2.34vw;
        box-shadow: 0px 0.25vw 0.5vw 0px #00001825;
        
        transition: transform 0.3s ease-in-out;
        background-color: #FAF9F2;
        .bubble-link 
        {
            display: block;
            
            height: 12vw;
            width: 100%;
            border-radius: 2.3vw;
            background-color: inherit;
        }
        .bubble-title
        {
            display: inline-block;
            position: relative;
            
            margin: 2.6vw 0 2vw 2.25vw;
            
            max-width: 50%;
            font-size: 1.56vw;
            font-weight: 600;
        }
        .bubble-title::after 
        {
            content: "";
            position: absolute;
            bottom: -0.65vw;
            left: 0;

            height: 0.26vw;
            width: 100%;
            background-color:#018EBB;
        }
        .bubble-content
        {
            margin-left: 2.25vw;
            max-width: 75%;
            
            line-height: 1.2vw;
            font-size: 0.8vw;
        }
        .arrow-effect
        {
            position: absolute;
            right: 1.3vw;
            top: 1.3vw;

            height: 2.8vw;
            width:2.8vw;
            border-radius: 100%;
            
            background-color: #000018;
            transition: all 0.3s ease-in-out;
            .arrow-effect img
            {
                position: absolute;
                top: 50%;
                left: 53%;
            
                transform: translate(-50%, -50%);
                transition: all 0.3s ease-in-out;
            }
        }
        &:hover
        {
            transform: translate(3px, -3px);
            box-shadow: -0.32vw 0.32vw 0px #018EBB;   
            .blue-grid
            {
                right: -2.4vw;
                top: -1.85vw;
            }
        }
    }
}