.veille-content 
{
    h1
    {
        margin-top: 0.5vw;

        font-size: 1.8vw;
        font-weight: 400;
        span img
        {
            margin-left: 0.85vw;

            height: 1.1vw;
            transform: rotate(90deg);
        }
    }
    .veille-description 
    {
        position:relative;
        
        margin: 6vw auto;
        width: 75%;
        .description-title
        {
            margin-bottom: 2vw;
            font-weight: 500;
            font-size: 2.08vw;
            span img 
            {
                margin-left: 1vw;
                height: 1.5vw;
            }
        }
        .description-content
        {
            max-width: 89%;

            text-align: justify;
            line-height: 1.95vw;
            font-size: 1.3vw;
        }
        .grey-grid 
        {
            position: absolute;
            left: -5.8vw;
            
            z-index: -1;
            height: 5vw;
            transform: rotate(90deg);
        }
    }
    .media 
    {
        .media-title
        {
            padding: 1vw;
            margin: 0 auto 4vw;

            width: 47vw;
            box-shadow: 0 0.26vw 0.52vw rgba(0, 0, 24, 0.07);
            border-radius: 2.35vw;
 
            font-size: 1.95vw;
            font-weight: 500;
        }
        .media-content
        {
            display: flex;
            margin: auto auto 7.5vw auto;

            height: 16vw;
            width: 80%;
            color: #FAF9F2;
            .media-element
            {
                strong 
                {
                    color: #018EBB;
                }
                display: flex;
                flex-direction: column;
                align-items: center;
            
                position: relative;
            
                background-color: #000018;
                border-radius: 2.35vw;
                &:first-child
                {
                    margin-right: 4.5vw;
                }
                .element-title
                {
                    margin:2.3vw 0 1.5vw 0;
                    font-weight: 400;
                }
                .element-description 
                {
                    margin:0 2vw;

                    text-align: justify;
                    line-height: 1.6vw;
                    font-size: 1.1vw;
                }
                .first-blue-grid, .second-blue-grid
                {
                    position: absolute;
                    right: 1.7vw;
                    
                    z-index: -1;
                    height: 5vw;
                }
                .first-blue-grid
                {
                    top: -2.5vw;
                }
                .second-blue-grid 
                {
                    bottom: -2.5vw;
                }
            }
        }
    }
    .analyse 
    {
        position: relative;
     
        margin: auto auto 10vw auto;
        padding-top: 1.5vw;
     
        height: 13.5vw;
        width: 82%;
        border-radius: 2.35vw;

        background-color: #E9E8E3;
        .analyse-title
        {
            margin-left: 1.5vw;

            font-weight: 500;
            font-size: 2.1vw;
        }
        .analyse-content
        {
            margin:2vw 1.5vw;
            
            font-size: 1.3vw;
            line-height: 1.8vw;
        }
        .first-blue-grid, .second-blue-grid
        {
            position: absolute;
            left: 8vw;
            
            z-index: -1;
            height: 5vw;
        }
        .first-blue-grid
        {
            top: -2.5vw;
        }
        .second-blue-grid 
        {
            bottom: -2.5vw;
        }
    }
}