@media screen and (max-width: 768px) {
    .comingSoon
    {
        .responsiveComingSoon 
        {
            display: block;

            position: relative;
            top: -15vw;
        }    
        .comingImage 
        {
            display: none;
        }
        .comingBtn
        {
            top: 150vw;
            left:50%;
            
            margin:auto;
            padding: 0 1vw 0 3vw;

            width: 84vw;
            height: 10vw;
            border: 1px solid #000018;
            font-size: 3.5vw;

            transform: translateX(-50%);
            span 
            {
                margin-left: auto;
                height: 8vw;    
                width: 8vw;
                img 
                {
                    height: 3vw;
                }
            }
            &:nth-child(3)
            {
                display: none;
            }
        }
        .footer-container 
        {
            margin-top: -35vw;
        }
    }

}