.example-container
{
    margin: 10vw 0 6vw 0;
    .sort-bar
    {
        display: flex;
        align-items: center;
        justify-content: center;

        margin-bottom: 3vw;
        height: 3.25vw;
        .active 
        {
            background-color: #018EBB;
            color: #FAF9F2;
            &:hover
            {
                color: #FAF9F2 !important;
            }
        }
        .sort-element
        {
            cursor: pointer;
            margin-right:1.5vw;
            padding: 1vw;

            border-radius: 6.5vw;
            box-shadow: 0 0 25px rgba(0, 0, 24, 0.15);
            
            font-size: 1.3vw;
            font-weight: 500;
            transition: all 0.2s ease-in-out;
            &:hover 
            {
                color: #018EBB;
            }
        }
    }
    .card-container 
    {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;

        margin: 0 10vw;
        width: 80.8vw;
    }
}