.video 
{
    .sub-part 
    {
        display: flex;
        justify-content:center;
        align-items: baseline;

        position: relative;
        
        margin: 7.8vw 4vw;
        padding: 0 1.3vw 1vw 1.3vw;
        
        height: 16vw;
        border-bottom: 0.52vw solid #018EBB;
        border-radius: 2.3vw;
        
        color: #FAF9F2;
        background-color: #000018;
        .social-network
        {
            display: flex;
            flex-direction:column;
            align-items: center;
            flex: 1;

            padding: 1.3vw 1.6vw;
            .sub-title
            {
                margin-top: 0.5vw;
            
                font-size: 1.3vw;
                font-weight: 500;
                span img
                {
                    margin-left: 0.85vw;
                    height: 1.1vw;
                    transform: rotate(90deg);
                }
            }
            .content
            {
                margin-top: 1.63vw;
            
                text-align: justify;
                line-height: 1.27vw;
                font-size: 0.85vw;
                font-weight: 400;
                strong 
                {
                    color: #018EBB;
                }
            }
        }
        .greyGrid 
        {
            position: absolute;
            right: -5.4vw;
            z-index: -1;
     
            height: 5vw;
            transform: rotate(90deg);
        }
    }
    .description-container 
    {
        position: relative;
     
        margin: 5vw auto 10vw;
        padding: 2vw 0 1.3vw 0;
     
        width: 74vw;
        height: 10vw;
        box-shadow: -0.13vw 0.26vw 0.52vw rgba(0, 0, 0, 0.15);
        border-radius: 2.3vw;
     
        background-color: #FAF9F2;
        h2 
        {
            margin: 0 0 2vw 2.5vw;
            
            font-size: 1.6vw;
            font-weight: 500;
        }
        p 
        {
            margin: 0 2.5vw;

            font-size: 1.3vw;
            line-height: 2.14vw;
        }
        .blueGrid
        {
            position: absolute;
            top: 0;
            left: -5.7vw;
            
            z-index: -1;
            height: 5vw;
            transform: rotate(90deg);
        }
    }
    .responsive-arrow 
    {
        display: none;
    }
}