.social 
{
    .social-grid 
    {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;

        margin: 10vw auto;
        width: 82vw;
        .grid-element 
        {
            position: relative;
            
            margin: 2vw;
            padding: 1.3vw;
            
            width: 40%;
            height: fit-content;
            border-radius: 2.3vw;
            box-shadow: 0px 4px 8px rgba(0, 0, 24, 0.07);
            
            background-color: #FAF9F2;
            .blueGrid 
            {
                position: absolute;
                bottom: 1.5vw;
                right: -6vw;
            
                z-index: -1;
                transform: rotate(90deg);
            }
            .greyGrid 
            {
                position: absolute;
                top: -0.7vw;
                left: -5.5vw;
            
                z-index: -1;
                transform: rotate(90deg);
            }
            .element-title
            {
                margin-bottom: 1.80vw;

                font-size: 1.8vw;
                font-weight:500 ;
                span img 
                {
                    margin-left: 1vw;
                    height: 1.2vw;
                    transform: rotate(90deg);
                }
            }
            .content
            {
                text-align: justify;
                line-height: 1.9vw;
                font-size: 1.17vw;
            }
        }
    }
}