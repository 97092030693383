.form-input
{
    .form-label
    {
        pointer-events:none;
        
        display: flex;
        flex-direction: column;

        position: absolute;
        
        margin: 1.85vw 1.70vw;
        
        width: 48.5%;
        font-size: 1.05vw;
        font-weight:500 ;
        transition: all 0.2s ease-in-out;
    }
    .shrink
    {
        transform: translateY(-1vw);

        font-size: 0.8vw;
        color: #005570;
    }
    input,
    textarea
    {
        position: relative;

        padding: 1.62vw 1.3vw;
        
        flex: 1;
        width: 100%;
        outline: none;
        border: none;
        border-radius: 1.3vw;
        
        background-color: #FAF9F2;
        font-size: 1.05vw;
        font-family: 'Poppins';
    }
    input:focus ~ .form-label,
    textarea:focus ~ .form-label
    {
        transform: translateY(-1vw);
        font-size: 0.8vw;
        color: #005570;
    }
    input 
    {
        margin-bottom:0;
    }
    textarea
    {
        height: 16.26vw;
        resize: none;
    }
}