/* Eric Meyer's Reset CSS */
/* v2.0 | 20110126 */
/* License: none (public domain) */
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section 
{
  display: block;
}
body 
{
  line-height: 1;
  color: #000018;
}
Link, a
{
  text-decoration: none;
  color: #000018;
}
ol, ul 
{
  list-style: none;
}
blockquote, q 
{
  quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after 
{
  content: '';
  content: none;
}
table 
{
  border-collapse: collapse;
  border-spacing: 0;
}
body 
{
  background-color: #faf9f2;
  font-family: 'Poppins', sans-serif;

}
.title
{
  margin-top: 12vw;

  font-size: 3.1vw;
  font-weight: 700;
  line-height: 4.7vw;
  span:first-of-type
  {
    border-bottom: 0.35vw solid #018EBB;
  }
  span:nth-of-type(2)
  {
    color: #018EBB;
  }
}
.title-container
{
  display: flex;
  align-items: center;
  h1
  {
    font-size: 2.1vw;
    font-weight:500;
  }
  .blueArrow 
  {
    margin-left: 1vw;
    height: 1.2vw;
    transform: rotate(90deg);
  }   
}
.citation 
{
  margin-top: 3.9vw;
  
  font-size: 1.3vw;
  line-height: 1.9vw;
  font-style: italic;
}